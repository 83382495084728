import { Component } from '../../../libs/components';


class FeesTableSwitcher extends Component {

    onInit(): void {
        this.host.querySelectorAll('.pillow-scroll-list__item__link').forEach((item: HTMLElement) => {
            item.addEventListener('click', event => {
                event.preventDefault();
                this.selectProduct(item.getAttribute('data-product'));
            });
        });
    }

    selectProduct(productName: string) {
        const activeClassForPillow = 'pillow-scroll-list__item__link--active';

        this.host.querySelectorAll('.pillow-scroll-list__item__link').forEach((item: HTMLElement) => {
            if (item.getAttribute('data-product') == productName) {
                item.classList.add(activeClassForPillow);
            } else {
                item.classList.remove(activeClassForPillow);
            }
        })


        const allTables = document.querySelectorAll('.table');
        const table = document.querySelector(`.table[data-product="${productName}"]`) as HTMLElement;
        allTables.forEach((item: HTMLElement) => {
            item.style.display = "none";
        });
        table.style.display = "flex";
    }
}
FeesTableSwitcher.declare('.pillow-scroll-list--fees');